import socketIOClient from 'socket.io-client';

// export const WS = process.env.REACT_APP_API as string;
// export let ws : Socket<DefaultEventsMap, DefaultEventsMap> = null;
// export const startWS = (id:string) =>{
//     ws = socketIOClient(process.env.REACT_APP_BACKEND_API as string,{
//         autoConnect:false,
//         extraHeaders:{ id }
//     });
// } 
// export const ws = socketIOClient(process.env.REACT_APP_BACKEND_API as string,{
//     autoConnect:false,
//     // extraHeaders:{ id: }
// });

export const ws = {
    on(s:any,sd:any){
        
    },
    off(s:any){

    },
    emit(s:string,sd:any){}
}