export const handleDisplayTime = (timestamp : number) =>{
    if(timestamp){
        const currYear = new Date().getFullYear();
        const currMonth = new Date().getMonth();
        const currDay = new Date().getDate();
        const year = new Date(timestamp).getFullYear();
        const month = new Date(timestamp).getMonth();
        const day = new Date(timestamp).getDate();
        if(year < currYear){
            return day + '/' + (month+1) + '/' + year;
        }else if(month < currMonth){
            return day + '/' + (month+1) + '/' + year;
        }else if(day < currDay){
            return day + '/' + (month+1) + '/' + year;
        }else{
            return new Date(timestamp).getHours() + ':' + String(new Date(timestamp).getMinutes()).padStart(2, "0");
        }
    }
    return
}


export const checkIsSameDay = (time1: number, time2: number) => {
    return (
      new Date(time1).getDate() === new Date(time2).getDate() &&
      new Date(time1).getMonth() === new Date(time2).getMonth() &&
      new Date(time1).getFullYear() === new Date(time2).getFullYear()
    );
  };
export const checkIsToday = (time: number) => {
    return (
      new Date(time).getDate() === new Date().getDate() &&
      new Date(time).getMonth() === new Date().getMonth() &&
      new Date(time).getFullYear() === new Date().getFullYear()
    );
  };

export const formattedDate = (time: number) => {
    return (
      new Date(time).getDate() +
      "/" +
      (new Date(time).getMonth() + 1) +
      "/" +
      new Date(time).getFullYear()
    );
  };