// import { IMessage } from "../types/chat";
import { UserState } from "../model/user";
import { GET_USERS } from "./userActions";



type UserAction = 
    {
        type: typeof GET_USERS;
        payload: { users: [] };
    }

export const userReducer = (state:UserState, action:UserAction)=> {
    switch (action.type){
        case GET_USERS:
            return {
                ...state,
                users: action.payload.users
            }
        default:
            return {...state};
    }
}