import { IMessage } from "../model/chat";

export const ADD_MESSAGE = "ADD_MESSAGE" as const;
export const GET_CONVERSATIONS = "GET_CONVERSATIONS" as const;
export const TOGGLE_CHAT = "TOGGLE_CHAT" as const;

export const addMessageAction = (message:IMessage) => ({
    type:ADD_MESSAGE,
    payload:{ message }
});

export const addConversationsAction = (history:[]) => ({
    type:GET_CONVERSATIONS,
    payload:{history}
})

export const toggleChatAction = (isOpen:boolean) =>({
    type:TOGGLE_CHAT,
    payload: { isOpen }
})