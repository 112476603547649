import { useEffect, useState } from 'react';
// import logo from './logo.svg';
import mathMavensLogo from './assets/images/math-mavens-logo.png';
import chatIcon from './assets/images/chat.png'
import './App.css';
// import socketIO from "socket.io-client"
import Chat from './pages/Chat';
import { ChatProvider } from './context/ChatContext';
import { UserProvider } from './context/UserContext';
import axios from "axios"
// import axiosInstances from './helper/axiosInstance';


function App() {
  const[login,setIsLogin] = useState<boolean>(false)
  const [userData,setUserData] = useState<any>({}) 
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [passwordShown, setPasswordShown] = useState(false);

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const handleRefreshToken = async() =>{
    const token = await localStorage.getItem('refreshToken');
    const url = process.env.REACT_APP_AUTH_API + '/api/auth/refresh' as string
    const tokenData:string = `Bearer ${token}` 
    axios.post( url ,{ headers: { 'Authorization': tokenData} }).then( async function(res){
      console.log(res,"REFRESHH")
      await setIsLogin(true)
      const data = res.data;
      const user = data.user;
      await setUserData(user)
      var token = data.accessToken;
      let refreshtoken = data.refreshToken;
      await localStorage.setItem("token", token);
      var dataUser = JSON.stringify(user);
      await localStorage.setItem("user", dataUser);
      await localStorage.setItem("refreshToken",refreshtoken);
    }).catch(function(error){
      
        console.log(error.response.data.message)
    })
  }

  const getUserData = async() =>{
    const token = await localStorage.getItem('token');
    const url = process.env.REACT_APP_AUTH_API + '/api/auth/me' as string
    const tokenData:string = `Bearer ${token}` 
    axios.get( url ,{ headers: { 'Authorization': tokenData} }).then( async function(response){
        await setUserData(response.data)
        await setIsLogin(true)
    }).catch(function(error){
      console.log(error,"ERRR")
      if(error.status === 401) handleRefreshToken();
        console.log(error.response.data.message)
    })
  }

  useEffect(()=>{
    getUserData()
  },[])

  const handleSubmit = async (e: { preventDefault: () => void; }) => {
    const url = process.env.REACT_APP_AUTH_API + '/api/auth/login' as string
    e.preventDefault();
    axios.post(url, {
      email: username,
      password: password,
    })
    .then(async (res) => {
      console.log(res)
      const data = res.data;
      const user = data.user;
      var token = data.accessToken;
      let refreshtoken = data.refreshToken;
      await localStorage.setItem("token", token);
      var dataUser = JSON.stringify(user);
      await localStorage.setItem("user", dataUser);
      await localStorage.setItem("refreshToken",refreshtoken);
      await window.location.reload()
    })
    .catch((err) => {
       console.log(err.message);
       alert(err)
    });
  }

  if(!login){
    return (
      <div className="App">
        <main className='grid md:grid-cols-2 gap-0'>
          <section className='section-left w-full hidden md:block'>
            <div className='my-[14.2rem] 2xl:my-[24rem]'>
              <div className="image-illustrator">
              <img
                  src={chatIcon}
                  className="w-4/12 h-4/12 drop-shadow flex justify-center items-center mx-auto my-auto"
                  alt="illustration_image"
                />
              </div>
              <div className="desc">
                <h1 className="text-primary text-center not-italic font-bold leading-10 text-md">
                  Welcome to Math Mavens Chats App
                </h1>
                <p className="text-primary text-center not-italic font-normal leading-6 text-md">
                  login now, and lets chat
                </p>
              </div>
            </div>
          </section>
          <section className='section-right w-full'>
            <div className='my-[16rem] flex-col justify-center items-center 2xl:my-[32rem]'>
              <div className='flex justify-center items-baseline my-4'>
                <img className='w-12 h-full' src={mathMavensLogo} alt="Math_Mavens_logo" />
                <h1 className="ml-2 text-primary text-center not-italic font-bold leading-10 text-xl">
                  Chats App
                </h1>
              </div>
              <form onSubmit={handleSubmit}>
              <div className='mb-4'>
                <input placeholder='Username' type="text" className='border h-12 w-81 rounded-full outline-none px-4 shadow-main text-sm' onChange={e=>setUsername(e.target.value)} value = {username} />
              </div>
              <div className='mb-4'>
                <input placeholder='Password' type={passwordShown ? "text" : "password"} className='border h-12 w-81 rounded-full outline-none px-4 shadow-main text-sm ml-6 ' onChange={e=>setPassword(e.target.value)} value = {password} />
                <button className='relative right-9 top-1.5 text-slate-400' type='button' onClick={togglePassword}>
                {passwordShown ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                  </svg>
                )}
                </button>
              </div>
              <div className='action mb-4'>
                <button type='submit' className='shadow-main button-primary rounded-full h-12 w-81 shadow-md ml-2'>Login</button>
              </div>
              </form>
            </div>
          </section>
        </main>
    </div>
    );
  }else{
    return <UserProvider initialUser={userData}><ChatProvider><Chat /></ChatProvider></UserProvider>
  }
  
 
}

export default App;
